import { render, staticRenderFns } from "./MagicCubePreview.vue?vue&type=template&id=457f6acf&scoped=true"
import script from "./MagicCubePreview.vue?vue&type=script&lang=js"
export * from "./MagicCubePreview.vue?vue&type=script&lang=js"
import style0 from "./MagicCubePreview.vue?vue&type=style&index=0&id=457f6acf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "457f6acf",
  null
  
)

export default component.exports